<div class="spinner-container"
     [hidden]="!isVisible"
     [ngClass]="{'full-screen': isFullScreen}">
    <div class="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
