<div class="topBar">
    <div class="topBar__inner">
        <a href="#" class="topBar__logo _eko"></a>
        <a href="#" class="topBar__logo">
            <img src="../../assets/img/logo-icon.svg" alt="" class="topBar__logoIcon">
            <div class="topBar__logoContent">
                <p class="topBar__logoName">
                    Большая Тройка
                </p>
                <p class="topBar__logoDescription">
                    АСУ “УПРАВЛЕНИЕ ОТХОДАМИ”
                </p>
            </div>
        </a>
    </div>
</div>